// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


const firebaseConfig = {
  apiKey: "AIzaSyC2BkcXiKBHndyHFl8aQo6RM4B6EGuHzLY",
  authDomain: "horacio-bort-official-50c67.firebaseapp.com",
  databaseURL: "https://horacio-bort-official-50c67-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "horacio-bort-official-50c67",
  storageBucket: "horacio-bort-official-50c67.appspot.com",
  messagingSenderId: "51780206578",
  appId: "1:51780206578:web:960922d4368eeb1485f56b",
  measurementId: "G-DZ80QWZC78"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getDatabase(app);