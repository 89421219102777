import styled, { keyframes } from "styled-components";

const fadeAnimation = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1}
`

const ConcertDiv = styled.div`
  animation: ${fadeAnimation} 1s 1 ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 4px double rgba(70, 71, 77, 0.5);

  @media (max-width: 767px) {
    margin: 0 1rem 1rem 1rem;
  }

  @media (min-width: 768px){
    min-height: 20rem;
    margin: 0 4rem 1rem 4.5rem;
  }
`

const NoConcerts = styled.p`
  text-align: center;
  text-indent: none;
  margin: 0;
  padding: 5rem 0 5rem 0;
`


const Concerts = () => {
  return (
    <ConcertDiv>
      <NoConcerts>No hay conciertos programados en este momento.</NoConcerts>
    </ConcertDiv>
  )
}

export default Concerts;