import styled from "styled-components";
import SocialCont from "./SocialCont";

const FirstName = styled.h1`
    border-bottom: 1px solid rgba(70, 71, 77, 0.5);
    padding-bottom: 0.25rem;
`;

const LastName = styled.h1`
    vertical-align: top;
`;

const HeaderCont = styled.div`
    margin: 0.5rem 0 0.5rem 0;
    text-align: center;
    font-size: 3rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    font-style: italic;

`;

const Header = () => {
    const first = "Horacio";
    const last = "Bort";

    return (
    <HeaderCont>
        <FirstName>{first}</FirstName>
        <LastName>{last}</LastName>
        <SocialCont/>
    </HeaderCont>);
}

export default Header;