import styled from "styled-components";
import { useState } from "react";

const NavMenuUl = styled.ul`
    display: flex;
    justify-content: space-evenly;
    list-style-type: none;
    padding-bottom: 2rem;

    .selected {
        opacity: 0.5;
    }
`;

const NavBtn = styled.li`
    transition: opacity 0.5s;

    :hover {
        cursor:pointer;
        opacity: 0.5;
        transition: opacity 0.5s;
    }

`;
const NavMenu = ({changeSection, contentIndex, navSelect}) => {
    const [selected, setSelected] = useState(0)
    const toggleSelected = () => {
        setSelected(contentIndex);
    }
    return(
        <div>
            <NavMenuUl>
                <NavBtn onClick={() => changeSection(0)} className={navSelect === 0 ? 'selected':null }>PROYECTO</NavBtn>
                <NavBtn onClick={() => changeSection(1)} className={navSelect === 1 ? 'selected':null }>NOTICIAS</NavBtn>
                <NavBtn onClick={() => changeSection(2)} className={navSelect === 2 ? 'selected':null }>MÚSICA</NavBtn>
                <NavBtn onClick={() => changeSection(3)} className={navSelect === 3 ? 'selected':null }>EN DIRECTO</NavBtn>
            </NavMenuUl>
        </div>
    );
}

export default NavMenu;