import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }

    html {
        font-family: 'Playfair Display SC';
        background: linear-gradient(#e4ecf7, rgba(133, 49, 49, 0.2)); 
        color: #46474d;
    }

    .App {
        min-height: 100vh;
    }

    p {
        font-family: 'Domine', 'Times New Roman'; 
        font-weight: 400;
        margin: 1rem 0.5rem 1rem 0.5rem; 
        line-height: 1.5rem; 
        text-indent: 2rem;
    }

`

export default GlobalStyle;