import styled from 'styled-components';

const FooterMenuCopy = styled.p`
    font-size: 0.85rem;
    padding: 0;
    margin: 0;
    text-indent: 0;
`

const MenuUl = styled.ul`
    display: flex;
    align-items: space-evenly;
    gap: 2rem;
    list-style: none;
`

const FooterMenuItem = styled.li`
    font-size: 0.75rem;
    text-decoration: none;

    a {
        text-decoration: none;
        color: inherit;
    }
`

const FooterMenuCont = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 1rem;
    align-items: center;
    
`

const FooterMenu = () => {
    return (
        <FooterMenuCont>
            <FooterMenuCopy>© horaciobort.com 2022</FooterMenuCopy>
            <MenuUl>
            <FooterMenuItem><a href="#top">inicio</a></FooterMenuItem>
            <FooterMenuItem><a href="mailto: contacto@horaciobort.com">contacto</a></FooterMenuItem>
            <FooterMenuItem><a href="https://www.freeprivacypolicy.com/live/322bd1bb-936e-4235-a0c3-74b163275fed">politica de privacidad</a></FooterMenuItem>
            </MenuUl>
        </FooterMenuCont>
    )
}

export default FooterMenu;