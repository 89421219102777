import styled from "styled-components";


const SocialDiv = styled.div`
    width: 100%;
    max-width: 450px;
    display: flex;
    justify-content: space-evenly;
    margin: 1.5rem 0 1.5rem 0;
`

const SocialLink = styled.a`
    text-decoration: none;
    color: inherit;
    font-size: 1.5rem;
    transition: opacity 0.5s;

    :hover {
        transition: opacity 0.5s;
        cursor:pointer;
        opacity: 0.5;
    }
`

const SocialCont = () => {
    return (
        <SocialDiv>
            <SocialLink className="fa fa-spotify" href="https://open.spotify.com/artist/6FViDmD90yAFxCwP6ZbTP6?si=mAqL5zWWT-6i-3ep2GtWeA" target="_blank" />
            <SocialLink className="fa fa-instagram" href="https://www.instagram.com/horaciobort/" target="_blank" />
            <SocialLink className="fa fa-youtube" href="https://www.youtube.com/channel/UCCvfxVVFvHEVjk8kQlPfr9A/featured" target="_blank" />
        </SocialDiv>
    )
}

export default SocialCont;