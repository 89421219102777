import styled, { keyframes } from "styled-components";

const fadeAnimation = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1}
`

const NewsCont = styled.div`
    animation: ${fadeAnimation} 1s 1 ease-in-out;
    border-bottom: 4px double rgba(70, 71, 77, 0.5);

    @media (max-width: 767px) {
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media (min-width: 768px){
        margin: 1rem 4.5rem 0rem 4rem;
        padding: 2.5rem 3rem 3.5rem 3rem;
        display: flex;
        justify-content: space-evenly;
    }
`
const NewsImg = styled.img`
    @media (max-width: 767px) {
        width: 90vw;
    }

    @media (min-width: 768px){
        height: 22.5rem;
    }
    
`
const NewsDesc = styled.p`
    @media (min-width: 768px) {
        width: 100%;
        padding: 0;
    }

    @media (min-width: 768px){
        width: 25rem;
        align-self: center;
        line-height: 2;
        margin-left: 1rem;
    }
    
`
const llagrimesText = `'Llàgrimes amb tu', ahora disponible en todas las 
    plataformas de streaming. Haz clic en imagen para escuchar.`

const somiantText = `"SOMIANT", dijous 24 de Febrer en totes les plataformes de streaming.
Fes clic a la imatge per a PREGUARDAR la cançó.
`

const News = () => {
    return (
        <div>
            <NewsCont>
                <a href="https://acqustic.ffm.to/horaciobort_somiant" target="_blank"><NewsImg src={require("../../src/assets/images/Somiant-cover.jpeg")} alt="Portada del single 'Somiant' de Horacio Bort."/></a>
                <NewsDesc>{somiantText}</NewsDesc>
            </NewsCont>
            <NewsCont>
                <a href="https://acqustic.ffm.to/horaciobort_llagrimesambtu" target="_blank"><NewsImg src={require("../../src/assets/images/cover_2.jpg")} alt="Portada del single 'Llagrimes Amb Tu' de Horacio Bort."/></a>
                <NewsDesc>{llagrimesText}</NewsDesc>
            </NewsCont>
        </div>
    )
}

export default News;