import styled, { keyframes } from "styled-components";

const fadeAnimation = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1}
`

const Description = styled.p`
    @media (min-width: 768px){
        margin: 2rem;
        margin-right: 1rem;
        line-height: 2;
    }

`
const ProjImg = styled.img`
    @media (min-width: 768px){
        height: 20rem;
        float: right;
        margin: 1rem;
    }
    @media (max-width: 767px){
        max-width: 90vw;
    }
`
const ProjDiv = styled.div`
    animation: ${fadeAnimation} 1s 1 ease-in-out;
    border-bottom: 4px double rgba(70, 71, 77, 0.5);

    @media (min-width: 768px){
        margin: 3rem 4rem 0rem 4rem;
    }
    @media (max-width: 767px){
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1rem;
    }
`

const Project = () => {
    const descrText = `Horacio Bort es un compositor natural de Valencia, que después de haber pertenecido 
    a otras bandas como instrumentista, en 2018 decide iniciar un proyecto de Indie Folk en solitario. 
    A los meses conoce al guitarrista de jazz estadounidense Noah Young con el que empieza a producir 
    las canciones que luego darán forma a su EP debut "Cometa H". En el transcurso, se unen Honor Witke al bajo 
    y Germán Rubio a la batería, dando por cerrada la formación del cantautor. Son canciones de tonos cálidos, 
    de nostalgia y tranquilidad que pretenden emocionar al que las escucha. En Abril de 2021 sale a la luz el 
    single "Una Mitad" y a mediados de Julio su último trabajo "En Mis Sueños". A finales de agosto, 
    la formación se reduce a dúo de nuevo, y junto al productor Raúl Abellán,  le dan forma a un 
    EP de cuatro canciones íntegramente en valenciano. El 13 de Enero  sale "Llàgrimes amb tu", 
    la primera de cuatro canciones que irán acompañadas de la obra de la artista Koi Samsa en forma de 
    portadas y vídeos.`;

    return(
        <ProjDiv>
            <ProjImg src={require('../../src/assets/images/Horacio_concerts.jpg')} alt="Horacio Bort, Noah Young en directo en 'Casa Kamarata', Benimaclet, Valencia, ES." />
            <Description>{descrText}</Description>
        </ProjDiv>
    );
}

export default Project;