import styled from "styled-components";
import FooterMenu from "./FooterMenu";
import { db } from "../util/firebase";
import { set, ref, push } from "firebase/database";

const handleSubmit = (e) => {
    e.preventDefault() // Avoids the browser from submitting the form and reloading

    let firstName = document.querySelector('#fName').value;
    let lastName = document.querySelector('#lName').value;
    let emailStr = document.querySelector('#email').value;
    let cityStr = document.querySelector('#city').value;

    set(ref(db, 'contacts/' + emailStr.split('@')[0]), {
        nombre: firstName,
        apellido: lastName,
        email: emailStr,
        ciudad: cityStr
    }).then(() => {
        // Do something more fancy here on success, like a modal or something
        
        window.location.reload()
    }).catch((err) => {
        // Would be nice to handle the error gracefully, warning the user
        window.alert("Estamos teniendo problemas técnicos. Intente más tarde.")
        window.location.reload()
    })
}

const FooterDiv = styled.div`
    @media screen && (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
`
const ContactForm = styled.form`
    max-width: 100vw;
    background: rgba(156, 129, 115, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .submit {
        width: 5rem;
        margin-top: 0.5rem;
        font-family: inherit;
        border-radius: 5px;
        background: light-gray;
    }
    input {
        max-width: 5rem;
        font-family: inherit;
    }
    
    @media (min-width: 768px){
        gap: 0.5rem;
        padding: 1rem 0 1rem 0;
        margin-bottom: 1rem;
    
        input {
            max-width: 10rem;
            font-family: inherit;
        }
    
        .submit:hover{
            cursor:pointer;
        }
    }
    @media (max-width: 767px) {
        margin: 1rem 0 1rem 0;
        padding: 0.5rem;

        .submit {
            margin: 1rem 0 0.5rem 0;
        }
    }
`
const FormGridDiv = styled.div`
    @media (max-width: 767px) {
        max-width: 100vw;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-row-gap: 1rem;
        grid-column-gap: 0.5rem;
        
    }
    
    @media (min-width: 768px) {
        max-width: 100vw;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-row-gap: 1rem;
        grid-column-gap: 2rem;
    }
`

const FormMessage = styled.p`
    font-style: italic;
    font-size: 0.85rem;
    margin: 0 0 0.5rem 0;
    padding: 0;
    justify-self: center;
    @media (max-width: 767px) {
        margin: 0.5rem 0 0.5rem 0;
        text-indent: 0;
        text-align: center;
    }
    
`

const EmailMsgDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.9rem;
    .email {
        font-style: italic;
        font-weight: bold;
    } 
    @media (min-width: 768px) {
        width: 100%;
        gap: 1rem;
        margin: 3rem 0 3rem 0;
        
    }
`

const EmailMsg = styled.p`
    margin: 0; 
    padding: 0;
    text-indent: 0;
`


const Footer = () => {
    return (
        <FooterDiv>
            <EmailMsgDiv>
                <EmailMsg>Para más información y contrataciones:</EmailMsg>
                <EmailMsg className="email">contacto@horaciobort.com</EmailMsg>
            </EmailMsgDiv>
            <ContactForm>
                <FormMessage>
                    Apúntate a la lista de contactos para recibir noticias sobre nueva música y actuaciones en directo.
                </FormMessage>
                <FormGridDiv>
                    <label>Nombre:</label>
                    <input id='fName' type='first-name' required/>
                    <label>Apellido:</label>
                    <input id='lName' type='last-name' required/>
                    <label>Email:</label>
                    <input id='email' type='email' required/>
                    <label>Ciudad:</label>
                    <input id='city' type='city' required/>
                </FormGridDiv>
                <button className="submit" type='submit' onClick={handleSubmit}>Enviar</button>
            </ContactForm>
            <FooterMenu />
        </FooterDiv>
    )   
}

export default Footer;