import  styled, { keyframes }  from "styled-components";
import { useState } from "react";
import GlobalStyle from "./globalStyles";
import Header from "./components/Header";
import NavMenu from "./components/NavMenu";
import Project from "./components/Project";
import News from "./components/News";
import Music from "./components/Music";
import Concerts from "./components/Concerts";
import Footer from "./components/Footer";
import './fonts.css';


const MainContainer = styled.div`
  max-width: 100%;
`

const TopHr = styled.hr`
  @media (min-width: 768px) {
    margin: 0 4rem 0 5rem;
  }

  @media (max-width: 767px) {
    margin: 0 1rem 0 1rem;
  }
  
`

function App() {
  const pageArr = [<Project />, <News />, <Music />, <Concerts />];
  const [contentIndex, setContentIndex] = useState(0)
  const [navSelect, setNavSelect] = useState(0)
  const changeSection = (newContentIndex) => {
    setContentIndex(newContentIndex);
    setNavSelect(newContentIndex);
  }

  return (
    <div className="App">
      <GlobalStyle />
      <Header/>
        <NavMenu navSelect={navSelect} changeSection={changeSection} contentIndex={contentIndex}/>
        <TopHr/>
      <MainContainer id="mainContainer">
        {pageArr[contentIndex]}
      </MainContainer>
      <Footer/>
    </div>
  );
}

export default App;
