import styled, { keyframes } from "styled-components";

const fadeAnimation = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1}
`

const MusicDiv = styled.div`
    animation: ${fadeAnimation} 1s 1 ease-in-out;

    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        margin: 0 0.5rem 0 0.5rem;
        .video {
            margin: 0 1rem 0 1rem; 
            width: 90vw;
            height: 30vh;
        }
    }
    @media (min-width: 768px){
        margin: 0 4rem 0 4rem;
        .video {
            height: 45vh;
            max-height: 25rem;
            width: 100%;
            min-width: 37.5vw;
            border-radius: 5px;
        }
        .textLeft {
            flex-direction: row-reverse;
        }
    }
    
`

const SectionCont = styled.div`
    display: flex;
    border-bottom: 4px double rgba(70, 71, 77, 0.5);

    @media (max-width: 767px) {
        margin: 0.5rem 0.5rem 1rem 0rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media (min-width: 768px){
        padding: 3rem 0 3rem 0;
        gap: 3rem;
        align-items: center;
    }
`

const VidDesc = styled.p`
    @media (min-width: 768px){
        line-height: 2;
    }

`

const Music = () => {
    return (
        <MusicDiv>
            <SectionCont>
                <iframe className="video" src="https://www.youtube.com/embed/oyMQyCwRIv8" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""/>
                <VidDesc>
                Gravació en directe de "Somiant". Música de Horacio Bort  (veus i guitarra acústica), Noah Young (guitarra elèctriques), Emi Pérez (violí). Videoproducció de Marcos Marinom (edició i càmera), y Sarah (càmera secundària). Gravat a Casa Kamarata, Benimaclet.
                </VidDesc>
            </SectionCont>
            <SectionCont className="textLeft">
                <iframe className="video" src="https://www.youtube.com/embed/FCvdOVxkRpc" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""/>
                <VidDesc>
                Gravació en directe de "Somiant". Música de Horacio Bort  (veus i guitarra acústica), y Noah Young (guitarra acústica). Videoproducció de Calle Sonora (edició i càmera). Gravat als carrers de Barcelona.
                </VidDesc>
            </SectionCont>
            <SectionCont>
                <iframe className="video" src="https://www.youtube.com/embed/FocwuhrR1io" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""/>
                <VidDesc>
                Gravació en directe de "Llàgrimes amb tu". Música de Horacio Bort  (veus i guitarra acústica), Noah Young (guitarra elèctriques), Emi Pérez (violí). Videoproducció de Marcos Marinom (edició i càmera), y Sarah (càmera secundària). Gravat a Casa Kamarata, Benimaclet.
                </VidDesc>
            </SectionCont>
            <SectionCont className="textLeft">
                <iframe className="video" src="https://www.youtube.com/embed/PutmCYqereg" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" />
                <VidDesc>
                Lyric Video del tema "Llàgrimes amb tu" de Horacio Bort. Gravat i produït per Raúl Abellán en "The Mixtery". Video produït i editat per Koi Samsa. Disponible en totes les plataformes oficials. Música per Horacio Bort, (composició, veus i guitarra acústica), Noah Young (producció, arranjaments i guitarres elèctriques).
                </VidDesc>
            </SectionCont>
            <SectionCont>
                <iframe className="video" src="https://www.youtube.com/embed/ongyLo-ejLk" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""/>
                <VidDesc>
                    Videoclip oficial del tema "Una Mitad" de Horacio Bort. Producción de Amberes Films. Disponible en todas las plataformas oficiales. Horacio Bort (composición, producción y guitarras acústicas), Noah Young (producción y guitarras eléctricas), Honor Witke (bajo eléctrico y coros), Germán Rubio (percusión).
                </VidDesc>
            </SectionCont>
            <SectionCont className="textLeft">
                <iframe className="video" src="https://www.youtube.com/embed/tWuUHMRVXqA" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" />
                <VidDesc>
                    Videoclip oficial del tema "En Mis Sueños" de Horacio Bort. Video producción de Ester Galán. Disponible en todas las plataformas oficiales. Horacio Bort (composición, producción y guitarras acústicas), Noah Young (producción, guitarras eléctricas y coros), Honor Witke (coros), Gonzalo Lagos (percusión).
                </VidDesc>
            </SectionCont>
            <SectionCont>
                <iframe className="video" src="https://www.youtube.com/embed/2hb8iEGtSR0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" />
                <VidDesc>
                "Cometa H" 27 Abril 2021. © Horacio Bort, Believe Music. Disponible en todas las plataformas oficiales. Horacio Bort (composición, producción y guitarras acústicas), Noah Young (producción y guitarras eléctricas).
                </VidDesc>
            </SectionCont>
        </MusicDiv>
    );
}

export default Music;